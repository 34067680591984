import LazyComponent from "@frontend/components/LazyComponent";
import RouterService from "@frontend/services/RouterService";
import { Route } from "react-router-dom";

const ROUTE = RouterService.create("expense-dashboard");

function Page() {
  return <LazyComponent factory={() => import("./LandingPage")} />;
}

export default <Route path={ROUTE.path} element={<Page />} />;
