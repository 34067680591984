import { BrowserRouter, Routes } from "react-router-dom";
import Route_LandingPage from "@frontend/pages/LandingPage";

import { ApiProvider } from "@frontend/components/ApiProvider";

function AppRouter() {
  return (
    <BrowserRouter>
      <ApiProvider>
        <Routes>{Route_LandingPage}</Routes>
      </ApiProvider>
    </BrowserRouter>
  );
}

export default AppRouter;
