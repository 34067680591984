import { z } from "zod";

const EnvSchema = z.object({
  BUILD_ENV: z.enum(["production", "staging", "development"]),
});

/** Environment variables. Used for secrets */
const EnvService = EnvSchema.parse({
  BUILD_ENV: process.env.BUILD_ENV,
});

export default EnvService;
