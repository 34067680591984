import {
  AuthenticatedUser,
  getAccessTokenPayloadSync,
  getAuthenticatedUser,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import React from "react";
import { z } from "zod";

const AuthService = {
  useCompany,
  useEmployeeId,
  useUser,
};
export default AuthService;

/** Currently selected employeeId */
function useEmployeeId() {
  const accessToken = getAccessTokenPayloadSync();

  if (!accessToken?.employeeId) {
    return null;
  }
  return accessToken.employeeId;
}

/** Currently selected company */
function useCompany() {
  const selectedCompany = useSelectedCompany();

  /** Currently selected company */
  const selectedCompanyParsed =
    selectedCompanySchema.safeParse(selectedCompany);

  if (!selectedCompanyParsed.success) {
    return null;
  }

  return selectedCompanyParsed.data.selectedCompany;
}

function useUser() {
  const [user, setUser] = React.useState<AuthenticatedUser | undefined>(
    undefined,
  );
  React.useEffect(() => {
    void getAuthenticatedUser().then((user) => setUser(user));
  }, []);
  return user;
}

const selectedCompanySchema = z.object({
  selectedCompany: z.object({
    /** Company id */
    id: z.string(),
    /** Company registration number */
    registrationNumber: z.string(),
  }),
});
