import AppRouter from "./routes";
import { ThemeProvider } from "@flash-tecnologia/hros-web-ui-v2";

import "../src/i18n";

export default function Root() {
  return (
    <ThemeProvider>
      <AppRouter />
    </ThemeProvider>
  );
}
