interface ServicesProps {
  [key: string]: {
    development: string;
    staging: string;
    production: string;
  };
}

export const services = {
  bff: {
    // development: "http://localhost:2021/trpc",
    development: "https://hros-expense-dashboard-bff.private.flashapp.dev/trpc",
    staging: "https://hros-expense-dashboard-bff.private.flashapp.dev/trpc",
    production:
      "https://hros-expense-dashboard-bff.us.private.flashapp.services/trpc",
  },
} as ServicesProps;
